import React from 'react'
import './style.scss'

export function UserGiftDetalis({ giftDetalis }) {
 
    return (
        <div className='userGiftDetalis-container'>
            <div className="column">
                <ul className='userGiftDetalis-content'>
                    <li>
                        <span><label>Email : </label>{giftDetalis.email}</span>
                    </li>
                    <li>
                        <span><label>Phone : </label>{giftDetalis.phone}</span>
                    </li>
                </ul>
                <ul className='userGiftDetalis-content'>
                    <li>
                        <span><label>Country : </label> {giftDetalis.country}</span>
                    </li>
                    <li>
                        <span><label>City : </label> {giftDetalis.city}</span>
                    </li>
                    <li>
                        <span><label>Address : </label> {giftDetalis.address}</span>
                    </li>
                    <li>
                        <span><label>Zip code : </label>{giftDetalis.zipCode}</span>
                    </li>
                </ul>
            </div>
            <div className="column">
                <ul className='userGiftDetalis-content center'>
                    <li>
                        <span><label>Zone : </label> {giftDetalis.zone}</span>
                    </li>
                    <li>
                        <span><label>Created at : </label> {giftDetalis.createdAt.slice(0, 10)}</span>
                    </li>
                    {/* <li>
                        <span><label>Updated at : </label> {giftDetalis.updatedAt.slice(0, 10)}</span>
                    </li> */}
                    {/* <li>
                        <span><label>Zone : </label> {giftDetalis.zone}</span>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}
