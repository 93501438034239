import React from 'react'
import './style.scss'

export  function Warning({text}) {
    return (
        <div className='warning-content'>
            <span>{'* ' }{text}</span>
        </div>
    )
}
