import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { finishedGamesCreate } from '../../store/actions/finishedGames'
import { Purchases } from '../../components/Purchases/Purchases'
import { NewUsers } from '../../components/NewUsers/NewUsers'
import './style.scss'

export function Dashboard(props) {
  const dispatch = useDispatch()
  const state = useSelector(state => state.finishedGameReducer)
  const { finishedGameData } = state

  useEffect(() => {
    const path = window.location.pathname.slice(1)
    if (path === 'dashboard') {
      dispatch(finishedGamesCreate({ start: null, and: null }))
    }
  }, [])

  return (
    <div className="dashbord-container">
      <div className="row">
        <div className="panel">
          <div className="panel-body">
            <span className="font-default">Finished Games - {finishedGameData}</span>
          </div>
        </div>
        <div className="panel">
          <div className="panel-body">
            <Purchases />
          </div>
        </div>
        <div className="panel">
          <div className="panel-body">
            <NewUsers />
          </div>
        </div>
      </div>
    </div>
  )
}
