import React, { useEffect } from 'react'
import { redChipIcon, vk, fb, ok, android, ios, telcell, easypay } from '../../assets'
import { useSelector, useDispatch } from 'react-redux'
import { getUserCreate } from '../../store/actions/getUser'
import { getUserTransitionPurchase } from '../../store/actions/GetUserTransition'
import { Link } from 'react-router-dom'


import './style.scss'

function UserSearchMemo({ user }) {
    const dispatch = useDispatch()
    const stateSearch = useSelector(state => state.userSearch)
    const { userData, isChangeLocation } = stateSearch

    const path = window.location.pathname

    useEffect(() => {
        if (isChangeLocation) {
            dispatch({ type: 'TO_ZERO' })
        }
    }, [path])


    const hanledeClickOnContent = (userId) => {
        if (userData) {
            dispatch(getUserCreate({ userId: userId }))
            dispatch(getUserTransitionPurchase({ userId: userId }))
            userData.length = 0
        }
    }

    return (
        <ul className="user-search-container"> {userData.length > 0 ? userData.map(el =>
            (<li key={el.userId}>
                <Link to='/user' className="userSearch-content"
                    onClick={(id) => hanledeClickOnContent(el.userId)}>
                    <div className="alert alert-info userRow table-hover ng-scope">
                        <div className="al-user-profile">
                            {el.avatar && <img src={el && el.avatar} alt='avatar' />}
                        </div>
                        <span className="font-default ng-binding">{el && el.fullName}</span>
                        <div className="flexRow flex-center">
                            <div className="al-user-profile">
                                <img src={redChipIcon} alt="redChip" />
                            </div>
                            <span className="font-default ng-binding">{el && el.red}</span>
                        </div><span className="font-default ng-binding">{el && el.level} level</span>
                        <div className="al-user-profile">
                            <img src={
                                (el.zone === 'fb' && fb) ||
                                (el.zone === 'vk' && vk) ||
                                (el.zone === 'ok' && ok) ||
                                (el.zone === 'ios' && ios) ||
                                (el.zone === 'android' && android) ||
                                (el.zone === 'telcell' && telcell) ||
                                (el.zone === 'easypay' && easypay)
                            } className="chip chip-vk" alt='chip' />
                        </div>
                    </div>
                </Link>
            </li>)
        ) : null}</ul>
    )
}
export const UserSearch = React.memo(UserSearchMemo)