import React  from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { TextFields } from '../TextField/TextField'
import { addUserNotesCreate, editUserNotesCreate } from '../../store/actions/notes'
import { Icon, } from '@iconify/react';
import closeIcon from '@iconify/icons-iwwa/close';
import './style.scss'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
        border: 'none !important',
        boxShadow: theme.shadows[5],
        width: 450,
        padding: '0px 2px'
    },
    title: {
        color: ' #7d7d7d !important',
        marginLeft: '15px',
        fontWeight: '500',
    },
    inputWrap: {
        position: 'relative',
        padding: '15px'
    },
    inputContent: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#6b6b6b',
        alignItems: 'center',
        fontSize: 14,
        marginBottom: 10,
    },
    input: {
        color: '#6b6b6b',
        backgroundColor: 'transparent',
        border: '1px solid #e7e7e7',
        width: '100%',
        height: '34px',
        padding: '6px 12px',
        fontSize: '14px',
        borderRadius: '5px',
        marginLeft: 2
    },


    btnWrap: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '13px',
        marginRight: '15px'
    },


}));

function ModalNotMemo({ title,
    isOpenModal,
    handleClose,
    ModalData,
    handleCloseModalNot,
    userId, value,
    handleChange }) {
    const classes = useStyles();
    const dispatch = useDispatch()


    const handleSubmit = (e) => {
        e.preventDefault()
        if (value.message) {
            if (title === 'Add') {
                dispatch(addUserNotesCreate(value))
            } else if (title === 'Edit' && value.messageId) {
                dispatch(editUserNotesCreate(value))
            }
        }
        handleCloseModalNot()
    }

    return (
        <div className="modal-edit-content">
            <Modal
                id='modal-content'
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={isOpenModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isOpenModal}>
                    <div className={classes.paper} id="modal">
                        <div className="icon-h-wrapp">
                            <h4 className={classes.title}  >{title} Notes</h4>
                            <Icon icon={closeIcon} onClick={handleCloseModalNot}
                                className='iconClose' />
                        </div>
                        <TextFields
                            label={'message'}
                            handleChange={handleChange}
                            value={value.message}
                            padding='16px'
                            width='100%' />
                        <div className={classes.btnWrap}>
                            <button className="btn-not" onClick={handleSubmit}>{title}</button>
                            <button className="btn-not" onClick={handleCloseModalNot}>Cancel</button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
export const ModalNot = React.memo(ModalNotMemo)

