import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AddModal } from "../../components/Modals/AddModal/AddModal";
import { SuccessModal } from "../../components/SuccessModal/SuccsessModal";
import {
  redChipIcon,
  greenChipIcon,
  yellowChipIcon,
  google,
  vk,
  fb,
  ok,
  android,
  ios,
  telcell,
  easypay,
} from "../../assets";
import { TransactionsPurchase } from "../../components/TransactionsPurchase/TransactionsPurchase";
import { star } from "../../assets";
import { chargeCreate, changeChip } from "../../store/actions/charge";
import AreYouSureModal from "../../components/Modals/AreYouSure/AreYouSureModal";
import "./style.scss";

export function UserPage(props) {
  const transitionState = useSelector(
    (state) => state.transitionPurchaseReducer
  );
  const getUserData = useSelector((state) => state.getUserData);
  const userGameState = useSelector((state) => state.getUserData.user);
  const { redChip, greenChip, yellowChip } = getUserData;
  const { user } = getUserData;
  const { success } = getUserData;

  const dispatch = useDispatch();

  function getSumDayes(day) {
    let dayFrom = new Date(user.vipDt);
    let dayTo = new Date(user.updatedAt);
    day =
      (dayFrom.getFullYear() - dayTo.getFullYear()) * 365 +
      ((dayFrom.getMonth() - dayTo.getMonth()) * 61) / 2 +
      dayFrom.getDate() -
      dayTo.getDate();
    return Math.floor(day);
  }
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openAreYouSure, setOpenAreYouSure] = useState(false);
  const [addAnyWay, setAddAnyWay] = useState(false);

  const handleCloseAreYouSure = () => {
    setOpenAreYouSure(false);
  };

  const handleOpenAreYouSure = () => {
    setOpenAreYouSure(true);
  };
  const handleOkAreYouSure = () => {
    setAddAnyWay(true);
    setOpenAreYouSure(false);
  };

  const handleOpen = (color) => {
    setType(color);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenSucces = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccsess = () => {
    setOpenSuccess(false);
  };
  const handleOkSuccsess = () => {
    setOpenSuccess(false);
  };

  useEffect(() => {
    if (success) {
      handleOpenSucces();
    }
  }, [success]);

  //-------------Addmodal
  const [value, setValue] = useState({
    actionType: "chip",
    userId: user && user.userId,
    amount: 1000,
    notify: true,
  });

  useEffect(() => {
    setValue({
      ...value,
      userId: user && user.userId,
    });
  }, [user]);

  const handleChange = (e) => {
    setValue({
      ...value,
      amount: e.target.value,
      userId: user && user.userId,
    });
  };
  console.log(value);

  const [state, setState] = useState({
    checked: true,
  });

  const handleChecked = (event) => {
    setState({ ...state, checked: event.target.checked });
    setValue({
      ...value,
      notify: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (value.amount < 0 && !addAnyWay) {
      handleOpenAreYouSure(e);
    }

    value.type = type;

    if (value.amount < 0 && addAnyWay) {
      e.preventDefault();
      //  setValue(value);
      dispatch(changeChip(value.amount, type));
      dispatch(chargeCreate(value));
      setValue({
        ...value,
        amount: 1000,
      });
      setAddAnyWay(false);
      handleClose();
    }
    if (value.amount > 0) {
      e.preventDefault();
      // setValue(value);
      dispatch(changeChip(value.amount, type));
      dispatch(chargeCreate(value));
      setAddAnyWay(false);
      setValue({
        ...value,
        amount: 1000,
      });
      handleClose();
    }
  };

  return (
    <>
      <div
        className="ng-scope"
        style={user ? { display: "block" } : { display: "none" }}
      >
        <AddModal
          open={open}
          handleClose={handleClose}
          handleOpen={handleOpen}
          handleSubmit={(e) => handleSubmit(e)}
          handleChecked={handleChecked}
          handleChange={handleChange}
          checked={state.checked}
          value={value.amount}
        />
        <AreYouSureModal
          handleOkAreYouSure={handleOkAreYouSure}
          handleOpenAreYouSure={handleOpenAreYouSure}
          openAreYouSure={openAreYouSure}
          handleCloseAreYouSure={handleCloseAreYouSure}
        />
        <SuccessModal
          handleOpenSucces={handleOpenSucces}
          handleCloseSuccsess={handleCloseSuccsess}
          handleOkSuccsess={handleOkSuccsess}
          openSuccess={openSuccess}
        />
        <div className="panel profile-page animated zoomIn">
          <div className="panel-body">
            <div className="flex-row" style={{ display: "flex" }}>
              <div
                className="flex-center "
                style={{ alignItems: "flex-start", marginLeft: "4%" }}
              >
                <div className="form-group row clearfix">
                  <div className="col-sm-9">
                    <div className="userpic">
                      {user && user.payed > 0 && (
                        <img className="star" src={star} alt="star" />
                      )}
                      <div className="userpic-wrapper">
                        {user && user.avatar && (
                          <img src={user ? user.avatar : null} alt="avatar" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="list-name">
                    <div className="alert font-default ng-binding">
                      {user && user.fullName} - {user && user.level} level
                    </div>
                    <div className="alert font-default ng-binding">
                      User ID: {user && user.userId}
                    </div>
                    <div className="alert font-default">
                      Zone:{" "}
                      <img
                        src={
                          (user && user.zone === "fb" ? fb : undefined) ||
                          (user && user.zone === "vk" ? vk : undefined) ||
                          (user && user.zone === "ok" ? ok : undefined) ||
                          (user && user.zone === "ios" ? ios : undefined) ||
                          (user && user.zone === "android"
                            ? android
                            : undefined) ||
                          (user && user.zone === "telcell"
                            ? telcell
                            : undefined) ||
                          (user && user.zone === "easypay"
                            ? easypay
                            : undefined) ||
                          (user && user.zone === "google" ? google : undefined)
                        }
                        className="chip chip-vk"
                        alt="chip"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ul-add">
                <div className="list-add">
                  <div className="flex-center ng-binding">
                    <img src={redChipIcon} className="chip" alt="chip" />{" "}
                    {user && user.red + redChip}
                  </div>

                  <button
                    onClick={(e) => handleOpen("red")}
                    style={{ background: "#e85656" }}
                  >
                    Add
                  </button>
                </div>
                <div className="list-add">
                  <div className="flex-center ng-binding">
                    <img src={greenChipIcon} className="chip" alt="chip" />{" "}
                    {user && user.green + greenChip}
                  </div>
                  <button
                    onClick={(e) => handleOpen("green")}
                    style={{ background: "green" }}
                  >
                    Add
                  </button>
                </div>
                <div className="list-add">
                  <div className="flex-center ng-binding">
                    <img src={yellowChipIcon} className="chip" alt="chip" />{" "}
                    {user && user.yellow + yellowChip}
                  </div>
                  <button
                    onClick={(e) => handleOpen("yellow")}
                    style={{ background: "yellow" }}
                  >
                    Add
                  </button>
                </div>
                <div className="row">
                  <div className="ul-add-bottom">
                    <div className="list-bottom-add">
                      Wins: {user && user.wins}{" "}
                    </div>
                    <div className="list-bottom-add">
                      Loses: {user && user.looses}{" "}
                    </div>
                    <div className="list-bottom-add">
                      BagesLevel: {user && user.bagesLevel}{" "}
                    </div>
                    <div className="list-bottom-add">
                      Paid total: {user && user.payed}{" "}
                    </div>
                    <div className="list-bottom-add">
                      VIP: {user && getSumDayes()} days
                    </div>
                    <div className="list-bottom-add">
                      Win Rate: {user && Math.round(user.rate * 100) + "%"}
                    </div>
                    <div className="list-bottom-add">
                      Old Win Rate:{" "}
                      {(user &&
                        Math.round(
                          (user.wins * 10000) / (user.wins + user.looses)
                        ) / 100) ||
                        0}{" "}
                      %{" "}
                    </div>
                    <div className="list-bottom-add">
                      Best Score: {user && user.bestScore}{" "}
                    </div>
                  </div>
                  <div className="devices">
                    <div className="list-bottom-add">
                      Device Type:
                      {user && user.deviceType
                        ? " " + user.deviceType
                        : " No Device"}
                    </div>
                    <div className="list-bottom-add">
                      Device Version:{" "}
                      {user && user.deviceVersion
                        ? " " + user.deviceVersion
                        : " No Device"}
                    </div>
                    <div className="list-bottom-add">
                      Game Version:{" "}
                      {user && user.gameVersion
                        ? " " + user.gameVersion
                        : " No Device"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="ng-scope"
        style={user ? { display: "block" } : { display: "none" }}
      >
        <TransactionsPurchase
          state={transitionState}
          userGameState={userGameState}
        />
      </div>
    </>
  );
}
