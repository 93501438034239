import React, { useEffect } from 'react'
import { ok, fb, easypay, telcell, android, ios, vk } from "../../assets"
import { purchasesCreate } from '../../store/actions/purchases'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'

export function Purchases( ) {
    const state = useSelector(state => state.purchaseReducer)
    const { purchases } = state
    const dispatch = useDispatch()
    useEffect(() => {
        if (!purchases) dispatch(purchasesCreate())
    }, [])
    return (

        <table className='purchase-container' >
            <tbody>
                <tr>
                    <th className="font-default">Purchases </th>
                </tr>
                <tr className='row-purchase'>
                    <td className="al-user-profile">
                        <img src={ios} alt="ios" />
                    </td>
                    <td>
                        <p className="center-text">{purchases &&  purchases.ios && purchases.ios.length}</p>
                    </td>
                </tr>
                <tr className='row-purchase'>
                    <td className="al-user-profile">
                        <img src={android} alt="android" />
                    </td>
                    <td>
                        <p className="center-text">{purchases && purchases.android && purchases.android.length}</p>
                    </td>
                </tr>
                <tr className='row-purchase'>
                    <td className="al-user-profile">
                        <img src={telcell} alt="telcell" />
                    </td>
                    <td>
                        <p className="center-text">{purchases && purchases.telcell && purchases.telcell.length}</p>
                    </td>
                </tr>
                <tr className='row-purchase'>
                    <td className="al-user-profile">
                        <img src={easypay} alt="easypay" />
                    </td>
                    <td>
                        <p className="center-text">{purchases && purchases.easypay && purchases.easypay.length}</p>
                    </td>
                </tr>
                <tr className='row-purchase'>
                    <td className="al-user-profile">
                        <img src={fb} alt="fb" />
                    </td>
                    <td>
                        <p className="center-text">{purchases && purchases.fb && purchases.fb.length}</p>
                    </td>
                </tr>
                <tr className='row-purchase'>
                    <td className="al-user-profile">
                        <img src={vk} alt="vk" />
                    </td>
                    <td>
                        <p className="center-text">{purchases && purchases.vk && purchases.vk.length}</p>
                    </td>
                </tr>
                <tr className='row-purchase'>
                    <td className="al-user-profile">
                        <img src={ok} alt="ok" />
                    </td>
                    <td>
                        <p className="center-text">{purchases && purchases.ok && purchases.ok.length}</p>
                    </td>
                </tr>
            </tbody>
        </table>

    )
}
