//getGiftCardsOrders
import {
    GET_GIFT_CARD_START,
    GET_GIFT_CARD_SUCCESS,
    GET_GIFT_CARD_FILED,
    UPDATE_GIFT_CARD_START,
    UPDATE_GIFT_CARD_SUCCESS,
    UPDATE_GIFT_CARD_FILED,
    UPDATE_GIFT_CARD_TO_FALSE,
    
} from "../action-types/actionTypes";
import { api } from '../../api/service'


export const changeToFalseisUpdategiftCards= () =>{
  return ({ type: UPDATE_GIFT_CARD_TO_FALSE});   
}

export const getGiftCardsOrdersCreate = () => {
    return async dispatch => {
        dispatch({
            type: GET_GIFT_CARD_START,
        });
        try {
            const response = await api.post('/getGiftCardsOrders');
         
            dispatch({
                type: GET_GIFT_CARD_SUCCESS,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: GET_GIFT_CARD_FILED,
                error: { message: "Something went wrong." }
            });
        }
    };
}

export const updateGiftCardsOrderCreate = (data) => {
    return async dispatch => {
        dispatch({
            type: UPDATE_GIFT_CARD_START,
        });
        try {
            const response = await api.post('/updateGiftCardsOrder', data);
      
            dispatch({
                type: UPDATE_GIFT_CARD_SUCCESS,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: UPDATE_GIFT_CARD_FILED,
                error: { message: "Something went wrong." }
            });
        }
    };
}


