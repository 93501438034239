import {
    GET_USER_NOTES_START,
    GET_USER_NOTES_SUCCESS,
    GET_USER_NOTES_FILED,
    ADD_USER_NOTES_START,
    ADD_USER_NOTES_SUCCESS,
    ADD_USER_NOTES_FILED,
    EDIT_USER_NOTES_START,
    EDIT_USER_NOTES_SUCCESS,
    EDIT_USER_NOTES_FILED,
} from "../action-types/actionTypes";
import { api } from '../../api/service'

//getnot
export const getUserNotesCreate = data => {
    return async dispatch => {
        dispatch({
            type: GET_USER_NOTES_START,
        });
        try {
            const response = await api.get(`/getUserNotes?userId=${data}`);
         
            dispatch({
                type: GET_USER_NOTES_SUCCESS,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: GET_USER_NOTES_FILED,
                error: { message: "Something went wrong." }
            });
        }
    };
}

//addnot
export const addUserNotesCreate = data => {
    return async dispatch => {
        dispatch({
            type: ADD_USER_NOTES_START
        })
        try {
            const response = await api.post('/postNote', data)
        dispatch({
                type: ADD_USER_NOTES_SUCCESS,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: ADD_USER_NOTES_FILED,
                error: { message: "Something went wrong." }
            })
        }
    }
}
//editNote 

export const editUserNotesCreate = data => {
    return async dispatch => {
        dispatch({
            type: EDIT_USER_NOTES_START
        })
        try {
            const response = await api.post('/editNote', data)
            dispatch({
                type: EDIT_USER_NOTES_SUCCESS,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: EDIT_USER_NOTES_FILED,
                error: { message: "Something went wrong." }
            })
        }
    }
}


