import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Search } from '../Search/Search'
import { RadioButtons } from '../RadioButton/RadioButton'
import { LogoutModal } from '../LogoutModal/LogoutModal'
import { menu } from '../../assets'
import { menuWhite } from '../../assets'
import { UserSearch } from '../UserSearch/UserSearch'
import { SearchLoudingIcon } from '../SearchLoudingIcon/SearchLoudingIcon'
import './style.scss'



export function Navbar({ handleShowModal, isChangeLocation }) {
    const stateAuth = useSelector(state => state.authReducer)
    const { role } = stateAuth
    const [hover, setHover] = useState(false)
    const state = useSelector(state => state.userSearch)
    const stateGetUser = useSelector(state => state.getUserData)
    const { userData, showSearch } = state
    const { user } = stateGetUser
    const { loading } = stateGetUser
    const styles = { marginLeft: '46%' }


//props for search
  const [selectedValue, setSelectedValue] = React.useState('User ID');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

    return (
        <div className="navbar-container">
            <div className="blot-admin">
                <p><Link to='/dashboard' >Bg<span>Admin</span> </Link></p>
                <div className="img-wrapp" onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} >
                    {hover ? <img src={menu} alt="menu" onClick={handleShowModal} /> :
                        <img src={menuWhite} alt="menu" />}
                </div>
            </div>
            <div className="search-radeo-wrapp">
                {userData && <UserSearch user={user} showSearch={showSearch} isChangeLocation={isChangeLocation}/>}
                  {loading && <div className="getting-user-spiner">
                      <SearchLoudingIcon fontSize={'large'} styles={styles} /> 
                      </div>}
                      {(role === 4 || role === 3)  && <Search selectedValue={selectedValue} />}
                <div className="radio-logo-wrapper">
                  { (role === 4 || role === 3)  && <RadioButtons selectedValue={selectedValue}  handleChange={handleChange} />}
                    <LogoutModal />
                </div>
            </div>
        </div>
    )
}
