import {
    GET_ACTIVE_GAME_START,
    GET_ACTIVE_GAME_SUCCESS,
    GET_ACTIVE_GAME_FILED,
} from "../action-types/actionTypes";


const initialState = {
    loading: false,
    activeGame: null,
    error: null,
}

export function activeGameReduser(state = initialState, action) {
    switch (action.type) {
        case GET_ACTIVE_GAME_START:
            return {
                ...state,
                loading: true
            }
        case GET_ACTIVE_GAME_SUCCESS:
            return {
                ...state,
                loading: false,
                activeGame: action.payload,
               }
        case GET_ACTIVE_GAME_FILED:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
            }
        default:
            return state;
    }
}


