import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px !important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    border: "none !important",
    boxShadow: theme.shadows[5],
  },
  title: {
    color: "#ffffff !important",
    fontWeight: "300",
    backgroundColor: "rgba(232, 86, 86, 0.85)",
    margin: 0,
    fontSize: 14,
    padding: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  span: {
    display: "flex",
    alignItems: "center",
  },
}));

function AreYouSureModal({
  openAreYouSure,
  handleCloseAreYouSure,
  message = "Are you sure",
  handleOkAreYouSure = () => {},
}) {
  const classes = useStyles();

  return (
    <div className="del-not-modal-content">
      <Modal
        id="modal-del-content"
        className={classes.modal}
        open={openAreYouSure}
        onClose={handleCloseAreYouSure}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAreYouSure}>
          <div className={classes.paper} id="modal-not-delete">
            <div className={classes.title}>
              <span className={classes.span}>
                <DoneIcon fontSize="small" />
                Are you sure?
              </span>
            </div>
            <div className="body">{message}</div>
            <div className="btn-checkbox-wrap">
              <button
                className="btn-not cancel"
                onClick={handleCloseAreYouSure}
              >
                Cancel
              </button>
              <button className="btn-not del" onClick={handleOkAreYouSure}>
                Ok
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
export default AreYouSureModal;
