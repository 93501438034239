import {
    GAMES_START,
    GAMES_SUCCESS,
    GAMES_FILED,
} from "../action-types/actionTypes";


const initialState = {
    loadingGame: false,
    games: null,
    error: null,
}

export function gameReduser(state = initialState, action) {
    switch (action.type) {
        case GAMES_START:
            return {
                ...state,
                loadingGame: true
            }
        case GAMES_SUCCESS:
            return {
                ...state,
                loadingGame: false,
                games: action.payload,
               }
        case GAMES_FILED:
            return {
                ...state,
                loadingGame: false,
                error: 'samething went wrong',
            }
        default:
            return state;
    }
}