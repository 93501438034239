import {
    GAMES_START,
    GAMES_SUCCESS,
    GAMES_FILED,
} from "../action-types/actionTypes";
import { api } from '../../api/service'


export const gamesCreate = data => {
    return async dispatch => {
        dispatch({
            type: GAMES_START,
        });
        try {
            const response = await api.post('/games', data);
            dispatch({
                type: GAMES_SUCCESS,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: GAMES_FILED,
                error: { message: "Something went wrong." }
            });
        }
    };
}


