import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import DoneIcon from '@material-ui/icons/Done';
import { deleteNotificationCreate, deleteListNotificationCreate } from '../../store/actions/notification'
import './style.scss'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px !important'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
        border: 'none !important',
        boxShadow: theme.shadows[5],

    },
    title: {
        color: '#ffffff !important',
        fontWeight: '300',
        backgroundColor: 'rgba(232, 86, 86, 0.85)',
        margin: 0,
        fontSize: 14,
        padding: 10,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5
    },
    span: {
        display: 'flex',
        alignItems: 'center'
    }

}));

function DeleteNotModalMemo({ deleteId, openDelNotModal, handleCloseDelNotModal }) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const handleDeleteNot = () => {
        dispatch(deleteNotificationCreate({ id: deleteId }))
        dispatch(deleteListNotificationCreate(deleteId))
         handleCloseDelNotModal()
    }


    return (
        <div className="del-not-modal-content">
            <Modal
                id='modal-del-content'
                className={classes.modal}
                open={openDelNotModal}
                onClose={handleCloseDelNotModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openDelNotModal}>
                    <div className={classes.paper} id="modal-not-delete">
                        <div className={classes.title}  >
                            <span className={classes.span} ><DoneIcon fontSize='small' />Are you sure?</span>
                        </div>
                        <div className="body">Are you sure, you want to delete notification?</div>
                        <div className="btn-checkbox-wrap">
                            <button className="btn-not cancel" onClick={handleCloseDelNotModal}>Cancel</button>
                            <button className="btn-not del" onClick={handleDeleteNot}>Delete</button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
export const DeleteNotModal = React.memo(DeleteNotModalMemo)