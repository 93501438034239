import React from 'react';
import './style.scss'

export function RadioButtons({ selectedValue, handleChange }) {
  

  return (
    <div className="radeo-container">
      <label className="container">User ID
   <input type="radio" checked={selectedValue === 'User ID'}
          name="radio" value="User ID" onChange={handleChange} />
        <span className="checkmark"></span>
      </label>
      <label className="container">Name
  <input type="radio" name="radio" checked={selectedValue === 'Name'}
          value="Name" onChange={handleChange} />
        <span className="checkmark"></span>
      </label>
    </div>
  );
}
