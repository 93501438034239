import React from 'react';
import {
    withStyles,
 
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import './style.scss'

const CssTextField = withStyles({
    root: {
          width: '100%',
         '& label.Mui-focused': {
            color: '#209e91c4',
       },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#209e91c4',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#209e91c4',
            },
            '&:hover fieldset': {
                borderColor: '#209e91c4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#209e91c4',
            },
        },
    },
})(TextField);

export function TextFields({ label, handleChange,  value, width, padding }) {


    return (
        <form noValidate style={{padding: padding}} >
            <CssTextField
                style={{width:width}}
                value={value}
                onChange={handleChange}
                id="custom-css-standard-input"
                label={label}
                variant="outlined"
                margin="dense" />
        </form>
    );
}