import {
    GET_GAME_DETALIS_START,
    GET_GAME_DETALIS_SUCCESS,
    GET_GAME_DETALIS_FILED,
    GET_ACTIVE_GAME_START,
    GET_ACTIVE_GAME_SUCCESS,
    GET_ACTIVE_GAME_FILED,
    DISABLE_GAME_START,
    DISABLE_GAME_SUCCESS,
    DISABLE_GAME_FILED
} from "../action-types/actionTypes";
import { api } from '../../api/service'




export const getGameDetalisCreate = data => {
    return async dispatch => {
        dispatch({
            type: GET_GAME_DETALIS_START,
        });
        try {
            const response = await api.post('/getGameDetails', data);
        
            dispatch({
                type: GET_GAME_DETALIS_SUCCESS,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: GET_GAME_DETALIS_FILED,
                error: { message: "Something went wrong." }
            });
        }
    };
}

export const getActiveGamesCreate = data => {
    return async dispatch => {
        dispatch({
            type: GET_ACTIVE_GAME_START,
        });
        try {
            const response = await api.post('/getActiveGames');
          
            dispatch({
                type: GET_ACTIVE_GAME_SUCCESS,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: GET_ACTIVE_GAME_FILED,
                error: { message: "Something went wrong." }
            });
        }
    };
}

export const disableGamesCreate = data => {
    return async dispatch => {
        dispatch({
            type: DISABLE_GAME_START,
        });
        try {
            const response = await api.post('/disableGames', data);
           
            dispatch({
                type: DISABLE_GAME_SUCCESS,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: DISABLE_GAME_FILED,
                error: { message: "Something went wrong." }
            });
        }
    };
}

