import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import searchIcon from '@iconify/icons-ion/search';
import { searchUserCreate } from '../../store/actions/searchCreate'
import { SearchLoudingIcon } from '../SearchLoudingIcon/SearchLoudingIcon'
import './style.scss'



export const Search = ({ selectedValue }) => {

    const [value, setValues] = useState({ queryValue: '' })
    const dispatch = useDispatch()
    const handleChange = (e) => {
        if (selectedValue === 'User ID') {
            setValues({
                ...value,
                queryKey: 'userId',
                queryValue: e.target.value
            })
        } else {
            setValues({
                ...value,
                queryKey: 'fullName',
                queryValue: e.target.value
            })
        }
    }

    useEffect(() => {
        if (selectedValue === 'User ID') {
            setValues({
                ...value,
                queryKey: 'userId'
            })
        } else {
            setValues({
                ...value,
                queryKey: 'fullName'
            })
        }
    }, [selectedValue])


    const isLoading = useSelector(state => state.userSearch.loading)
    const [isSubmit, setSubmit] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault()
        const { queryValue } = value
        if (queryValue.length > 0 && queryValue.length > 2 && selectedValue === 'Name') {
            setValues({
                ...value,
                queryKey: 'fullName',
                queryValue: queryValue.trim()
            })
        }
        if (queryValue.length > 0 && !isNaN(queryValue) && selectedValue === 'User ID') {
            setValues({
                ...value,
                queryKey: 'userId',
                queryValue: queryValue.trim()
            })
        }
        setSubmit(true)
        dispatch({ type: "CHANGE_LOCATION_FALSE" })
    }

    useEffect(() => {
        if (isSubmit) {
            dispatch(searchUserCreate(value))
            setSubmit(false)
        }
    }, [isSubmit])

    return (
        <form action="" className="search-container" onSubmit={handleSubmit} >
            <div className="wrapper-srch-icon">
                {(value.queryValue && isLoading) ? <SearchLoudingIcon fontSize={'small'} /> :
                    <Icon icon={searchIcon} height={'1.0rem'} width={"1.0rem "}
                        onClick={handleSubmit} />}
            </div>
            <input
                onChange={handleChange}
                value={value.queryValue}
                type="text" placeholder='Search for...' />
        </form>

    )
}
