import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import './style.scss'

export function TopContant() {
   
    const path = window.location.pathname.slice(1)
    if(path === ''){
        return <Redirect to='dashboard' />
    }
    return (
        <div className="content-top clearfix">
            <h1 className="al-title ng-binding">{path=== 'active' ? 'active games' : path}</h1>
            <ul className="breadcrumb al-breadcrumb">
                <li><Link to="/dashboard">Home</Link></li>
                <li id="liName">{path=== 'active' ? 'Active Games' : path}</li>
            </ul>
        </div>
    )
}
