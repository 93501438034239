import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import DoneIcon from '@material-ui/icons/Done';
import { chargeBackCreate } from '../../store/actions/chargeBack'
import './style.scss'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px !important'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
        border: 'none !important',
        boxShadow: theme.shadows[5],

    },
    title: {
        color: '#ffffff !important',
        fontWeight: '300',
        backgroundColor: 'rgba(144, 185, 0, 0.85)',
        margin: 0,
        fontSize: 14,
        padding: 10,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5
    },
    span: {
        display: 'flex',
        alignItems: 'center'
    }

}));

function ModalAreYouSureMemo({ openAreYouSure, handleCloseAreYouSure, dataForCharge }) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const handleCharge = () => {
        dispatch(chargeBackCreate({ games: dataForCharge }))
        handleCloseAreYouSure()
    }



    return (
        <div className="del-not-modal-content">
            <Modal
                id='modal-del-content'
                className={classes.modal}
                open={openAreYouSure}
                onClose={handleCloseAreYouSure}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openAreYouSure}>
                    <div className={classes.paper} id="modal-not-delete">
                        <div className={classes.title}  >
                            <span className={classes.span} ><DoneIcon fontSize='small' />Are you sure?</span>
                        </div>
                        <div className="body"> <p style={{ margin: 0 }} >Are you sure, you want to charge back</p>
                            <p style={{ margin: 0, textAlign: 'center' }}>users for selected games?</p> </div>
                        <div className="btn-checkbox-wrap">
                            <button className="btn-not cancel" style={{ background: '#209e91' }}
                                onClick={handleCloseAreYouSure}>Cancel</button>
                            <button className="btn-not del" style={{ background: '#7a9d00' }}
                                onClick={handleCharge}>Charge</button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
export const ModalAreYouSure = React.memo(ModalAreYouSureMemo)