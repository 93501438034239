
import {
    NOTIFICATION_START,
    NOTIFICATION_SUCCESS,
    NOTIFICATION_FILED,
    CREATE_NOT_LIST,
    DELETE_NOT_LIST,
    UPDATE_NOT_LIST
} from "../action-types/actionTypes";


const initialState = {
    loading: false,
    notificationData: null,
    createRowNot: null,
    updateRowNot: null,
    removeId: null,
    error: null,
}

export function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATION_START:
            return {
                ...state,
                loading: true
            }
        case NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationData: action.payload
            }
        case NOTIFICATION_FILED:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
            }
        case CREATE_NOT_LIST:
            return {
                ...state,
                createRowNot: action.payload
            }
            case UPDATE_NOT_LIST :
                return {
                    ...state,
                    updateRowNot: action.payload 
                }
        case DELETE_NOT_LIST:
            return {
                ...state,
                removeId: action.payload
            }
        default:
            return state;
    }
}