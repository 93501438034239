import React, { useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';
import { checkLoginCreate } from '../store/actions/auth'
import { Prelouder } from '../components/Prelouder/Prelouder'




export const PrivateRoute = ({ component: Component, ...rest }) => {
   const state=  useSelector(state => state.authReducer)
   const { role } = state
 
   const dispatch =  useDispatch()
useEffect(()=>{
    dispatch(checkLoginCreate())
},[dispatch])

  return (
      <> {role ?  <Route {...rest} render={props => (
            localStorage.getItem('token')  ?
                <Component {...props} />
                : <Redirect to="/sign-in" />
        )} /> : <Prelouder />} </>
    );
};

