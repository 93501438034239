import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";

const ChartMemo = ({
  dataArrTotal,
  dataArrMicro,
  dataArrBig,
  dataArrFriends,
  dataArrTraining,
  dataArrBonus,
  dataArrProf,
  dataArrUnfinished,
  dataArrFast,
  dataArrClassic,
  dataForPurch,
}) => {
  const [labels, setLabels] = useState("");

  useEffect(() => {
    if (dataArrTotal) {
      setLabels(dataArrTotal);
    }
  }, [dataArrTotal]);

  dataForPurch &&
    dataForPurch.sort((a, b) =>
      new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
    );

  let repetProperty = {
    fill: false,
    lineTension: 0.3,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBackgroundColor: "#fff",
    pointBorderWidth: 3,
    pointHoverRadius: 3,
    pointHoverBackgroundColor: "#cc317c",
    pointHoverBorderWidth: 3,
    pointRadius: 3,
    pointHitRadius: 10,
  };
  const yDefault = {
    yAxes: [
      {
        ticks: {
          stepSize: 1,
          suggestedMax: 5,
        },
      },
    ],
  };
  // console.log('labels', labels);
  console.log("dataForPurch", dataForPurch);
  console.log("label", labels && labels.map((el) => el.started));
  const data = {
    labels:
      (labels && labels.map((el) => el.started)) ||
      0 ||
      (dataForPurch && dataForPurch.map((el) => el.createdAt)),
    datasets: [
      {
        ...repetProperty,
        backgroundColor: "#cc317c",
        borderColor: "#cc317c",
        pointBorderColor: "#cc317c",
        pointHoverBackgroundColor: "#cc317c",
        pointHoverBorderColor: "#cc317c",
        data: dataArrTotal && dataArrTotal.map((el) => el.count),
        label: "total",
      },
      {
        ...repetProperty,
        backgroundColor: "#dfb81c",
        borderColor: "#dfb81c",
        pointBorderColor: "#dfb81c",
        pointHoverBackgroundColor: "#dfb81c",
        pointHoverBorderColor: "#dfb81c",
        data: dataArrMicro && dataArrMicro.map((el) => el.count),
        label: "micro",
      },
      {
        ...repetProperty,
        backgroundColor: "#90b900",
        borderColor: "#90b900",
        pointBorderColor: "#90b900",
        pointHoverBackgroundColor: "#90b900",
        pointHoverBorderColor: "#90b900",
        data: dataArrBig && dataArrBig.map((el) => el.count),
        label: "big",
      },
      {
        ...repetProperty,
        backgroundColor: "#5e109e",
        borderColor: "#5e109e",
        pointBorderColor: "#5e109e",
        pointHoverBackgroundColor: "#5e109e",
        pointHoverBorderColor: "#5e109e",
        data: dataArrFriends && dataArrFriends.map((el) => el.count),
        label: "friends",
      },
      {
        ...repetProperty,
        backgroundColor: "#209e91",
        borderColor: "#209e91",
        pointBorderColor: "#209e91",
        pointHoverBackgroundColor: "#209e91",
        pointHoverBorderColor: "#209e91",
        data: dataArrTraining && dataArrTraining.map((el) => el.count),
        label: "training",
      },
      {
        ...repetProperty,
        backgroundColor: "#e85656",
        borderColor: "#e85656",
        pointBorderColor: "#e85656",
        pointHoverBackgroundColor: "#e85656",
        pointHoverBorderColor: "#e85656",
        data: dataArrBonus && dataArrBonus.map((el) => el.count),
        label: "bonus",
      },
      {
        ...repetProperty,
        backgroundColor: "#269abc",
        borderColor: "#269abc",
        pointBorderColor: "#269abc",
        pointHoverBackgroundColor: "#269abc",
        pointHoverBorderColor: "#269abc",
        data: dataArrProf && dataArrProf.map((el) => el.count),
        label: "prof",
      },
      {
        ...repetProperty,
        backgroundColor: "#333",
        borderColor: "#333",
        pointBorderColor: "#333",
        pointHoverBackgroundColor: "#333",
        pointHoverBorderColor: "#333",
        data: dataArrUnfinished && dataArrUnfinished.map((el) => el.count),
        label: "unfinished",
      },
      {
        ...repetProperty,
        backgroundColor: "#1b867b",
        borderColor: "#1b867b",
        pointBorderColor: "#1b867b",
        pointHoverBackgroundColor: "#1b867b",
        pointHoverBorderColor: "#1b867b",
        data: dataArrFast && dataArrFast.map((el) => el.count),
        label: "fast",
      },
      {
        ...repetProperty,
        backgroundColor: "#281168",
        borderColor: "#281168",
        pointBorderColor: "#281168",
        pointBackgroundColor: "#fff",
        pointHoverBackgroundColor: "#281168",
        pointHoverBorderColor: "#281168",
        data: dataArrClassic && dataArrClassic.map((el) => el.count),
        label: "classic",
      },
      {
        ...repetProperty,
        backgroundColor: "#209e91",
        borderColor: "#209e91",
        pointBorderColor: "#209e91",
        pointHoverBackgroundColor: "#209e91",
        pointHoverBorderColor: "#209e91",
        data: dataForPurch && dataForPurch.map((el) => el.count),
        label: "purchase",
      },
    ],
  };
  return (
    <Line
      data={data}
      height={110}
      options={{
        legend: {
          display: false,
        },
        scales: yDefault,
      }}
      style={{ marginTop: 20 }}
    />
  );
};

export const Chart = React.memo(ChartMemo);
