import React from 'react'
import './style.scss'

export function Offers() {
    return (
        <div className='offers-container'>
            <h1>Offers</h1>
            <h1>Offers</h1>
            <h1>Offers</h1>
        </div>
    )
}
