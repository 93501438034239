import {
    SEARCH_START_USER,
    SEARCH_SUCCESS_USER,
    SEARCH_FILED_USER,
} from "../action-types/actionTypes";
import { api } from '../../api/service'



export const searchUserCreate = data => {
    return async dispatch => {
        dispatch({
            type: SEARCH_START_USER
        });
        try {
            const response = await api.post('/search', data);
        dispatch({
                type: SEARCH_SUCCESS_USER,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: SEARCH_FILED_USER,
                error: { message: "Something went wrong." }
            });
        }
    };
}