
import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FILED,
    SIGN_OUT_START,
    SIGN_OUT_SUCCESS,
    SIGN_OUT_FILED,
    CHECK_LOGIN_START,
    CHECK_LOGIN_SUCCESS,
    CHECK_LOGIN_FILED
} from "../action-types/actionTypes";


const initialState = {
    loading: false,
    user: null,
    error: null,
    status: false,
    role: null,
}

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_START:
            return {
                ...state,
                loading: true
            }
        case AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                user: action.payload.user,
                role: action.payload.user.role
}
        case AUTH_FILED:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
                user: null,
            }
        case CHECK_LOGIN_START:
            return {
                ...state,
                loading: false,
            }
        case CHECK_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.user,
                role: action.payload.user.role
            }
        case CHECK_LOGIN_FILED:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
                user: null,
            }
        case SIGN_OUT_START:
            return {
                ...state,
                loading: false,
            }
        case SIGN_OUT_SUCCESS:
            return {
                ...state,
                loading: false,
                user: null,
                role: null
            }
        case SIGN_OUT_FILED:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
            }
        default:
            return state;
    }
}

// support
// avatar: "https://cdn.blotclub.am/site/images/logo.svg"
// createdAt: null
// fullName: "support"
// id: 13
// password: "ee53d4213c897ad632bb8d824762f918"
// role: 3
// updatedAt: null
// username: "support

// 1)statistics


// avatar: null
// createdAt: null
// fullName: "Lusine Pribilskaya"
// id: 16
// password: "ee53d4213c897ad632bb8d824762f918"
// role: 4
// updatedAt: null
// username: "lusinep"

// avatar: "https://cdn.blotclub.am/site/images/logo.svg"
// createdAt: null
// fullName: "stats"
// id: 9
// password: "ee53d4213c897ad632bb8d824762f918"
// role: 2
// updatedAt: null
// username: "stats"

