import {
    NEW_USERS_START,
    NEW_USERS_SUCCESS,
    NEW_USERS_FILED,
} from "../action-types/actionTypes";
import { api } from '../../api/service'


export const newUsersCreate = () => {
    return async dispatch => {
        dispatch({
            type: NEW_USERS_START,
        });
        try {
            const response = await api.post('/newUsers');
         dispatch({
                type: NEW_USERS_SUCCESS,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: NEW_USERS_FILED,
                error: { message: "Something went wrong." }
            });
        }
    };
}