import React from 'react'
import './style.scss'

export function Prelouder() {
    return (

        <div id="preloader" ng-show="!$pageFinishedLoading" className="ng-hide">
            <div></div>
        </div>

    )
}
