import React from 'react'
import './style.scss'

export function Tournaments() {
    return (
        <div className='tournaments-container'>
            <h1>Tournaments</h1>
            <h1>Tournaments</h1>
            <h1>Tournaments</h1>
        </div>
    )
}