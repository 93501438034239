import axios from "axios";
const baseURL =
  process.env.REACT_APP_BUILD_ENV !== "production"
    ? "https://testgame.belotkings.com/api/admin"
    : "https://game.belotkings.com/api/admin";
export const api = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.response.use(
  function (response) {
    if (response.data.status === "200") {
    }
    return response;
  },

  function (error) {
    if (!error.response) {
      return Promise.reject({
        response: {
          status: 500,
          message: "Something went wrong.",
        },
      });
    } else if (error.response.status === 401) {
      console.log("error 401", error);
      localStorage.removeItem("token");
      window.location = "/sign-in";
    } else {
      return Promise.reject(error);
    }
  }
);
