import {
    GET_USER_TRANSITION_START,
    GET_USER_TRANSITION_SUCCESS,
    GET_USER__TRANSITION_FILED,
} from "../action-types/actionTypes";
import { api } from '../../api/service'



export const getUserTransitionPurchase = data => {
    return async dispatch => {
        dispatch({
            type: GET_USER_TRANSITION_START,
        });
        try {
            const response = await api.post('/getUserTransactionsPurchases', data);
           dispatch({
                type: GET_USER_TRANSITION_SUCCESS,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: GET_USER__TRANSITION_FILED,
                error: { message: "Something went wrong." }
            });
        }
    };
}