import { getDays } from "../../components/Methods/Methods";
export const gamesSeries = [
  { name: "total", color: "#cc317c" },
  { name: "micro", color: "#dfb81c" },
  { name: "big", color: "#90b900" },
  { name: "training", color: "#209e91" },
  { name: "bonus", color: "#e85656" },
  { name: "prof", color: "#269abc" },
  { name: "unfinished", color: "#333" },
  { name: "fast", color: "#1b867b" },
  { name: "classic", color: "#281168" },
  { name: "friends", color: "#5e109e" },
];

export const purchase = [
  "fb",
  "vk",
  "ok",
  "ios",
  "android",
  "telcell",
  "easypay",
  "idram",
];
export function getCount(arr, str) {
  let count = 0;
  arr.forEach((el) => {
    if (el.roomType === str) {
      count++;
    }
  });
  return count;
}

export function changeDataToMonth(arr, start, end, isDifferentYear) {
  arr.pop();
  let mlist = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let dataForMonth = [];
  let countJanuary = 0,
    countFebruary = 0,
    countMarch = 0,
    countApril = 0,
    countMay = 0,
    countJune = 0;
  let countJuly = 0,
    countAugust = 0,
    countSeptember = 0,
    countOctober = 0,
    countNovember = 0,
    countDecember = 0;
  // console.log('start', start, end, isDifferentYear)
  //sum count for a month

  arr &&
    arr.forEach((el, i) => {
      if (el.started.slice(5, 7) === "01") {
        countJanuary += el.count;
      }
      if (el.started.slice(5, 7) === "02") {
        countFebruary += el.count;
      }
      if (el.started.slice(5, 7) === "03") {
        countMarch += el.count;
      }
      if (el.started.slice(5, 7) === "04") {
        countApril += el.count;
      }
      if (el.started.slice(5, 7) === "05") {
        countMay += el.count;
      }
      if (el.started.slice(5, 7) === "06") {
        countJune += el.count;
      }
      if (el.started.slice(5, 7) === "07") {
        countJuly += el.count;
      }
      if (el.started.slice(5, 7) === "08") {
        countAugust += el.count;
      }
      if (el.started.slice(5, 7) === "09") {
        countSeptember += el.count;
      }
      if (el.started.slice(5, 7) === "10") {
        countOctober += el.count;
      }
      if (el.started.slice(5, 7) === "11") {
        countNovember += el.count;
      }
      if (el.started.slice(5, 7) === "12") {
        countDecember += el.count;
      }
    });
  // console.log('arr1', arr)//{started: "2019-11-04", count: 5}
  dataForMonth.push(
    { started: mlist[0], count: countJanuary },
    { started: mlist[1], count: countFebruary },
    { started: mlist[2], count: countMarch },
    { started: mlist[3], count: countApril },
    { started: mlist[4], count: countMay },
    { started: mlist[5], count: countJune },
    { started: mlist[6], count: countJuly },
    { started: mlist[7], count: countAugust },
    { started: mlist[8], count: countSeptember },
    { started: mlist[9], count: countOctober },
    { started: mlist[10], count: countNovember },
    { started: mlist[11], count: countDecember }
  );

  let a,
    b,
    arr3 = [];
  dataForMonth.forEach((el, i) => {
    if (el.started.slice(0, 3) === start) {
      a = i;
    }
    if (el.started.slice(0, 3) === end) {
      b = i;
    }
  });

  //if between date tiv@ tarber a.
  if (isDifferentYear) {
    let arr1 = dataForMonth.slice(0, b + 1);
    let arr2 = dataForMonth.splice(a);
    arr3 = [...arr2, ...arr1];
  } else {
    arr3 = dataForMonth.slice(a, b + 1);
  }
  return arr3;
}
//.slice(a, b + 1)
//2020-02-18 2020-02-28
export function dataMutation(arr, roomType, start, end) {
  let arrData = [];
  // cut dates for correct formats
  arr = arr.map((el) =>
    el ? { ...el, started: formatDate(el.started) } : null
  );

  //filter micro objects
  if (roomType === "total") {
    function getArrGamesTotal() {
      arrData = arr;
      return arrData;
    }
    getArrGamesTotal();
  } else {
    function getArrGamesCharSeries() {
      arrData = arr && arr.filter((el) => el.roomType === roomType);
      return arrData;
    }
    getArrGamesCharSeries();
  }

  // add count of micro to dupicated dates
  let uniqueObj = {};
  if (arrData) {
    for (let i = 0; i < arrData.length; i++) {
      if (uniqueObj[arrData[i].started]) {
        uniqueObj[arrData[i].started]++;
      } else {
        uniqueObj[arrData[i].started] = 1;
      }
    }
  }

  var finalArrDataArr = Object.keys(uniqueObj).map((uniqueDate) => ({
    started: uniqueDate,
    count: uniqueObj[uniqueDate],
  }));

  // adding missing dates for X offset
  let getDaysArray = function (s, e) {
    let a = [];
    for (let d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d));
    }

    return a;
  };

  let daylistArr =
    finalArrDataArr && finalArrDataArr.length > 0 && getDaysArray(start, end);
  let daylist =
    daylistArr && daylistArr.map((v) => v.toISOString().slice(0, 10));
  daylist &&
    daylist.forEach((el) => {
      if (!finalArrDataArr.includes(el)) {
        finalArrDataArr.push({ started: el, count: 0 });
      }
    });
  finalArrDataArr.sort((a, b) =>
    new Date(a.started) > new Date(b.started) ? 1 : -1
  );
  for (let i = 0; i < finalArrDataArr.length; i++) {
    if (
      i < finalArrDataArr.length - 1 &&
      finalArrDataArr[i].started === finalArrDataArr[i + 1].started
    ) {
      finalArrDataArr.splice(finalArrDataArr.indexOf(finalArrDataArr[i]), 1);
    }
  }
  let t =
    finalArrDataArr[finalArrDataArr.length - 1] &&
    new Date(finalArrDataArr[finalArrDataArr.length - 1].started);
  let addOneDay = t && new Date(t.setDate(t.getDate() + 1));
  let convertIso = addOneDay && addOneDay.toISOString().slice(0, 10);
  finalArrDataArr.push({ started: convertIso, count: 0 });
  return finalArrDataArr;
}

////------------------------------------------------
export function getTotal(arr, name) {
  let total = 0;
  arr.forEach((el) => {
    total += el[name];
  });
  return total;
}

// for purchase
function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function createDataForDay(arr, key, start, end, isShowDay) {
  arr = arr.map((el) =>
    el
      ? {
          ...el,
          createdAt: !isShowDay
            ? formatDate(el.createdAt)
            : new Date(el.createdAt),
        }
      : null
  );
  let uniqueObj = {};
  if (arr) {
    console.log("arr1", arr);
    for (let i = 0; i < arr.length; i++) {
      if (uniqueObj[arr[i][key]]) {
        uniqueObj[arr[i][key]]++;
      } else {
        uniqueObj[arr[i][key]] = 1;
      }
    }
  }
  // console.log('uniq', uniqueObj);
  // console.log('arr', arr);
  var finalArrDataArr = Object.keys(uniqueObj).map((uniqueDate) => ({
    createdAt: uniqueDate,
    count: uniqueObj[uniqueDate],
  }));

  let getHoursArray = function (s, e) {
    console.log("s   e", s, e);
    let a = [];
    for (let d = new Date(s); d <= e; d.setHours(d.getHours() + 1)) {
      a.push(new Date(d));
    }

    return a;
  };

  function createHours(hour) {
    let res = "";
    if (new Date(hour).getHours().toString().length === 1) {
      res = "0" + new Date(hour).getHours().toString();
    } else {
      res = new Date(hour).getHours().toString();
    }
    return res;
  }

  let finalArrDataArr1 = finalArrDataArr
    .map((el) => ({ ...el, createdAt: el.createdAt.toString().slice(3, 21) }))
    .map((el) => ({
      ...el,
      createdAt: el.createdAt.slice(0, 13) + createHours(el.createdAt) + ":00",
    }));

  //2020-07-05
  console.log("finalArrDataArr1", finalArrDataArr1);
  let uniqueObj1 = {};
  if (finalArrDataArr1) {
    for (let i = 0; i < finalArrDataArr1.length; i++) {
      if (uniqueObj1[finalArrDataArr1[i][key]]) {
        uniqueObj1[finalArrDataArr1[i][key]]++;
      } else {
        uniqueObj1[finalArrDataArr1[i][key]] = 1;
      }
    }
  }
  console.log("uniqueObj1", uniqueObj1);
  var finalArrDataArr2 = Object.keys(uniqueObj1).map((uniqueDate) => ({
    createdAt: uniqueDate,
    count: uniqueObj1[uniqueDate],
  }));

  //datark datayi texer@ jam@ mek count 0 enq avelacnum
  let daylistArr =
    finalArrDataArr2 &&
    finalArrDataArr2.length > 0 &&
    getHoursArray(start, end).map((el) => el.toString().slice(3, 21));
  console.log("daylistArr", daylistArr);

  daylistArr &&
    daylistArr.forEach((el) => {
      if (!finalArrDataArr2.includes(el)) {
        finalArrDataArr2.push({ createdAt: el, count: 0 });
      }
    });

  console.log("finalArrDataArr2", finalArrDataArr2);
  finalArrDataArr2.sort((a, b) =>
    new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
  );
  for (let i = 0; i < finalArrDataArr2.length; i++) {
    if (
      i < finalArrDataArr2.length - 1 &&
      finalArrDataArr2[i].createdAt === finalArrDataArr2[i + 1].createdAt
    ) {
      finalArrDataArr2.splice(finalArrDataArr2.indexOf(finalArrDataArr2[i]), 1);
    }
  }
  return finalArrDataArr2;
}

export function changeDataToMonthPurch(arr = [], start, end, isDifferentYear) {
  arr.pop();
  let mlist = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let dataForMonth = [];
  let countJanuary = 0,
    countFebruary = 0,
    countMarch = 0,
    countApril = 0,
    countMay = 0,
    countJune = 0;
  let countJuly = 0,
    countAugust = 0,
    countSeptember = 0,
    countOctober = 0,
    countNovember = 0,
    countDecember = 0;
  // console.log('start', start, end, isDifferentYear)
  //sum count for a month
  // console.log('arr1', arr)
  arr &&
    arr.forEach((el, i) => {
      if (el.createdAt.slice(5, 7) === "01") {
        countJanuary += el.count;
      }
      if (el.createdAt.slice(5, 7) === "02") {
        countFebruary += el.count;
      }
      if (el.createdAt.slice(5, 7) === "03") {
        countMarch += el.count;
      }
      if (el.createdAt.slice(5, 7) === "04") {
        countApril += el.count;
      }
      if (el.createdAt.slice(5, 7) === "05") {
        countMay += el.count;
      }
      if (el.createdAt.slice(5, 7) === "06") {
        countJune += el.count;
      }
      if (el.createdAt.slice(5, 7) === "07") {
        countJuly += el.count;
      }
      if (el.createdAt.slice(5, 7) === "08") {
        countAugust += el.count;
      }
      if (el.createdAt.slice(5, 7) === "09") {
        countSeptember += el.count;
      }
      if (el.createdAt.slice(5, 7) === "10") {
        countOctober += el.count;
      }
      if (el.createdAt.slice(5, 7) === "11") {
        countNovember += el.count;
      }
      if (el.createdAt.slice(5, 7) === "12") {
        countDecember += el.count;
      }
    });

  // console.log('arr2', arr)//{started: "2019-11-04", count: 5}
  dataForMonth.push(
    { createdAt: mlist[0], count: countJanuary },
    { createdAt: mlist[1], count: countFebruary },
    { createdAt: mlist[2], count: countMarch },
    { createdAt: mlist[3], count: countApril },
    { createdAt: mlist[4], count: countMay },
    { createdAt: mlist[5], count: countJune },
    { createdAt: mlist[6], count: countJuly },
    { createdAt: mlist[7], count: countAugust },
    { createdAt: mlist[8], count: countSeptember },
    { createdAt: mlist[9], count: countOctober },
    { createdAt: mlist[10], count: countNovember },
    { createdAt: mlist[11], count: countDecember }
  );

  let a,
    b,
    arr3 = [];
  dataForMonth.forEach((el, i) => {
    if (el.createdAt.slice(0, 3) === start) {
      a = i;
    }
    if (el.createdAt.slice(0, 3) === end) {
      b = i;
    }
  });

  if (isDifferentYear) {
    let arr1 = dataForMonth.slice(0, b + 1);
    let arr2 = dataForMonth.splice(a);
    let tempArr = [...arr2, ...arr1];

    console.log("tempArr", tempArr);
    function oppArr() {
      let arrOppoz = [];
      for (let i = tempArr.length - 1; i >= 0; i--) {
        arrOppoz.push(tempArr[i]);
      }
      return arrOppoz;
    }
    arr3 = [...oppArr(tempArr)];
  } else {
    // console.log('-------a, b', a, b, start, end)
    let tempArr = dataForMonth.slice(a === 0 ? a : a - 1, b + 1);
    // console.log('tempArr', tempArr)
    function oppArr() {
      let arrOppoz = [];
      for (let i = tempArr.length - 1; i >= 0; i--) {
        arrOppoz.push(tempArr[i]);
      }
      return arrOppoz;
    }
    arr3 = [...oppArr(tempArr)];
  }

  return arr3;
}

export function createData(arr, key, start, end) {
  arr = arr.map((el) =>
    el ? { ...el, createdAt: formatDate(el.createdAt) } : null
  );
  let uniqueObj = {};
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      if (uniqueObj[arr[i][key]]) {
        uniqueObj[arr[i][key]]++;
      } else {
        uniqueObj[arr[i][key]] = 1;
      }
    }
  }
  // console.log('uniq', uniqueObj);
  // console.log('arr', arr);
  var finalArrDataArr = Object.keys(uniqueObj).map((uniqueDate) => ({
    createdAt: uniqueDate,
    count: uniqueObj[uniqueDate],
  }));
  console.log("finalArrDataArr", finalArrDataArr);
  let getDaysArray = function (s, e) {
    let a = [];
    for (let d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d));
    }

    return a;
  };
  // return finalArrDataArr;
  let daylistArr =
    finalArrDataArr && finalArrDataArr.length > 0 && getDaysArray(start, end);
  console.log("daylistArr", daylistArr);
  let daylist =
    daylistArr && daylistArr.map((v) => v.toISOString().slice(0, 10));
  console.log("daylist", daylist);
  daylist &&
    daylist.forEach((el) => {
      if (!finalArrDataArr.includes(el)) {
        finalArrDataArr.push({ createdAt: el, count: 0 });
      }
    });

  return finalArrDataArr;
}
