import React from 'react'


export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }


    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        console.log('this.state.error',this.state.error)
    }

    render() {
        console.log('this.props', this.props.children)
        if (this.state.error) {
            // You can render any custom fallback UI
            return <>
                <h1>Something went wrong.</h1>
                <h2>{this.state.error}</h2>
            </>;
        }

        return this.props.children;
    }
}