import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { notificationCreate } from '../../store/actions/notification'
import { yellowChipIcon, greenChipIcon, redChipIcon, vk, ok, fb, ios, android } from '../../assets'
import EditIcon from '@material-ui/icons/Edit';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { EditCreateNotModal } from '../../components/NotModals/EditCreateNotModal'
import { DeleteNotModal } from '../../components/NotModals/DeleteNotModal'
import { Tooltip } from '@material-ui/core';
import './style.scss'
import { SendModal } from '../../components/NotModals/SendModal';
import { DeleteButton } from '../../components/DeleteButton/DeleteButton'

export function Notifications() {
    const dispatch = useDispatch()
    const state = useSelector(state => state.notificationReducer)
    const { notificationData, createRowNot, updateRowNot, removeId } = state
    const CopyNotificationData = { ...notificationData }
    const { notifications } = CopyNotificationData
    const [todos, setToto] = useState(notifications)



    useEffect(() => {
        const path = window.location.pathname.slice(1)
        if (path === 'notifications') {
            dispatch(notificationCreate())
        }
    }, [])

    useEffect(() => {
        setToto(notifications)
    }, [notifications])

    //add id to adding list
    useEffect(() => {
        if (Array.isArray(todos) && createRowNot) {
            let findMaxIdArr = todos.reduce((prev, current) => (
                prev.id > current.id ? prev : current
            ))
            createRowNot.id = findMaxIdArr.id + 1
            setToto([...todos, createRowNot])
        }
    }, [createRowNot])

    // update list values
    useEffect(() => {
        if (updateRowNot) {
            let updateList = todos.map(el => (
                el.id === updateRowNot.id ? el = updateRowNot : el
            ))
            setToto(updateList)
        }
    }, [updateRowNot])

    //remove row from arr
    useEffect(() => {
        if (removeId) {
            let tempArr = todos.filter(el => el.id !== removeId)
            setToto(tempArr)
        }
    }, [removeId])


    //create update modal props-----------------------------------

    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState({})
    const [buttonText, setButtonText] = useState('')
    const [deleteId, setDeteleId] = useState(null)
    const [openDelNotModal, setOpenDelNotModal] = useState(false);

    const handleOpenEditNot = (...data) => {
        setEditData({
            ...editData,
            title: data[0],
            type: data[1],
            text: data[2],
            bonus: data[3],
            platforms: data[4],
            active: data[5],
            auto: data[6],
            id: data[7]
        })
        setButtonText('Update')
        setOpen(true);
    };

    const handleOpenCreateNot = (e) => {
        setEditData({
            ...editData,
            active: true,
            auto: true,
            bonus: [{ type: 'red', amount: 1000 }, { type: 'yellow', amount: 0 }, { type: 'green', amount: 0 }],
            platforms: ["fb", "vk", "ok", "ios", "android"],
            text: "",
            title: "",
            type: "passiveUsers"
        })
        setButtonText('Create')
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseDelNotModal = () => {
        setOpenDelNotModal(false)
    }
  
    const handleOpenDelNotModal = (id) => {
        setDeteleId(id)
        setOpenDelNotModal(true)
    }

    // props for sendmodal ------------------------------------
    const [openSendModal, setOpenSendModal] = useState(false)
    const [sendModalData, setSendModalData] = useState({})

    const handleOpenSendNotModal = (...data) => {
        setSendModalData({
            ...sendModalData,
            platforms: data[0],
        })
        setOpenSendModal(true)
    }
    const handleCloseSendModal = () => {
        setOpenSendModal(false)
    }

    const renderList = todos && todos.map((el, index) => {
        return <tr key={index}>
            <Tooltip title={el.title ? el.title : ''} arrow placement="bottom-start" ><td className='text'>{el.title}</td></Tooltip>
            <Tooltip title={el.text ? el.text : ''} arrow placement="bottom-start" ><td className='text'>{el.text}</td></Tooltip>
            <td className="bonus">{el.bonus !== null && el.bonus.map((el, i) => (
                <span key={i}>{el.amount}{' '}
                    <img src={
                        (el.type === 'red' && redChipIcon) ||
                        (el.type === 'yellow' && yellowChipIcon) ||
                        (el.type === 'green' && greenChipIcon)
                    } alt="chip" />
                </span>))} </td>
            <td className='platforms'>{el.bonus !== null && el.platforms.map((el, i) => (
                <img key={i} src={
                    (el === 'fb' && fb) || (el === "vk" && vk) ||
                    (el === "ok" && ok) || (el === "ios" && ios) ||
                    (el === "android" && android)} alt="icons" />
            ))}</td>
            <td>{el.type}</td>
            <td><button onClick={(e) => handleOpenEditNot(el.title, el.type, el.text, el.bonus, el.platforms, el.active, el.auto, el.id)} className='edit-btn'>
                <EditIcon style={{ color: 'white' }} fontSize='small' />
            </button></td>
            <td><button className='not-btn'>
                <NotificationsIcon style={{ color: '#666666' }} onClick={(e) => handleOpenSendNotModal(el.platforms, el.createdAt, el.updatedAt)} fontSize='small' />
            </button></td>
            <td><DeleteButton handleClick={(id) => handleOpenDelNotModal(el.id)} /></td>
        </tr>;
    });

    return (
        <div className='notifications-container'>
            <EditCreateNotModal buttonText={buttonText} open={open} handleClose={handleClose} editData={editData} />
            <SendModal sendModalData={sendModalData} openSendModal={openSendModal} handleCloseSendModal={handleCloseSendModal} editData />
            <DeleteNotModal deleteId={deleteId} openDelNotModal={openDelNotModal} handleCloseDelNotModal={handleCloseDelNotModal} />
            <div className='panel profile-page animated zoomIn'>
                <div className="panel-body">
                    <div className="body-content">
                        <div className="header-content">
                            <h3 className="header">Notifications</h3>
                            <button className='plus-btn' onClick={handleOpenCreateNot} ><ControlPointIcon /></button>
                        </div>
                        <div className="horizontal-scroll">
                            <table className="table">
                                <thead>
                                    <tr className="sortable">
                                        <th style={{ width: '80%' }}>Title </th>
                                        <th style={{ width: '80%' }}>Text </th>
                                        <th style={{ width: '105%' }}>Bonus </th>
                                        <th style={{ width: '100%' }}>Platforms </th>
                                        <th style={{ width: '50%' }}>Type </th>
                                        <th style={{ width: '26%' }}>Edit </th>
                                        <th style={{ width: '26%' }}>Send </th>
                                        <th style={{ width: '26%' }}>Delete </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderList}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


