
import {
    FINISHED_GAME_START,
    FINISHED_GAME_SUCCESS,
    FINISHED_GAME_FILED,
} from "../action-types/actionTypes";


const initialState = {
    loading: false,
    finishedGameData: null,
    error: null,
  }

export function finishedGameReducer(state = initialState, action) {
    switch (action.type) {
        case FINISHED_GAME_START:
            return {
                ...state,
                loading: true
            }
        case FINISHED_GAME_SUCCESS:
            return {
                ...state,
                loading: false,
                finishedGameData: action.payload
            }
        case FINISHED_GAME_FILED:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
            }
        default:
            return state;
    }
}