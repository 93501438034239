import {
    CHARGE_BACK_START,
    CHARGE_BACK_SUCCESS,
    CHARGE_BACK_FILED,
} from "../action-types/actionTypes";


const initialState = {
    loading: false,
    status: null,
    error: null,
}

export function chargeBackReduser(state = initialState, action) {
    switch (action.type) {
        case CHARGE_BACK_START:
            return {
                ...state,
                loading: true
            }
        case CHARGE_BACK_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action.payload.status,
               }
        case CHARGE_BACK_FILED:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
            }
        default:
            return state;
    }
}


