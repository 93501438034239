import {
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER__FILED,
  INCREMENT_DECREMENT,
  CHARGE_CHIP_START,
  CHARGE_CHIP_SUCCESS,
  CHARGE_CHIP__FILED,
} from "../action-types/actionTypes";

const initialState = {
  loading: false,
  user: null,
  error: null,
  redChip: 0,
  yellowChip: 0,
  greenChip: 0,
  success: false,
};

export function getUserData(state = initialState, action) {
  switch (action.type) {
    case INCREMENT_DECREMENT:
      return {
        ...state,
        redChip:
          action.payload[1] === "red" &&
          state.redChip + Number(action.payload[0]),
        yellowChip:
          action.payload[1] === "yellow" &&
          state.yellowChip + Number(action.payload[0]),
        greenChip:
          action.payload[1] === "green" &&
          state.greenChip + Number(action.payload[0]),
      };
    case GET_USER_START:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        redChip: 0,
        yellowChip: 0,
        greenChip: 0,
        success: false,
      };
    case GET_USER__FILED:
      return {
        ...state,
        loading: false,
        error: "samething went wrong",
      };
    case CHARGE_CHIP_START:
      return {
        ...state,
        success: false,
      };
    case CHARGE_CHIP_SUCCESS:
      return {
        ...state,
        success: action.payload.status,
      };
    case CHARGE_CHIP__FILED:
      return {
        ...state,
        success: false,
        error: "samething went wrong",
      };
    default:
      return state;
  }
}
