import React from 'react'
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import './style.scss'

export function GameDetalis({ id, gameData,
    userId, handleClickPlus, handleClickMinus, isShowUser }) {


    return (
        <div className='gameDetalis-container'>

            {gameData.length > 0 && id && gameData.filter(el => id === el._id).map((item, i) => {

                return (<ul className='ul' key={item.gameId} >
                    <li>Game id:  {item.gameId}</li>
                    <li>Users:  {' ' + item.users[0].userId + ', '}   {item.users[1].userId + ', '}{item.users[2].userId + ', '}{item.users[3].userId + ', '} </li>
                    <li>Score:  {item.globalScore[1] + ' : ' + item.globalScore[0]} </li>
                    <li>Winners : {item.winnerTeam}</li>
                    <li>"Bazar info: {!isShowUser.includes(item.gameId) ?
                        <AddIcon className='plus-icon' onClick={(id) => handleClickPlus(item.gameId)} /> :
                        <RemoveIcon className='plus-icon' onClick={(id) => handleClickMinus(item.gameId)} />}
                    </li>
                    {isShowUser.includes(item.gameId) && item.bazarInfoList.map((item, i) => {
                        return (
                            <ul className='opened-ul' key={item._id} >
                                <li>
                                    <span>team : {item.team}</span>
                                    <span>userId : {item.userId}</span>
                                    <span>contract: {item.suit + " "},  {item.value}</span>
                                    <span>capout: {item.isCapout ? ' yes' : ' no'}</span>
                                    <span>contra: {item.contra.status ? ' yes ' : ' no '}, {item.contra.userId} </span>
                                    <span>recontra: {item.recontra.status ? ' yes ' : ' no '}, {item.recontra.userId} </span>
                                    <span>success: {item.isSuccess ? ' yes' : ' no'}</span>
                                </li>
                            </ul>
                        )
                    })}
                </ul>)
            })}
        </div>
    )
}

