import React from 'react';
import './style.scss'
export function NativeSelects({ value, handleChange, createColorFromStatus }) {


  return (
    <select
      value={value}
      style={{ background: `${createColorFromStatus}` }}
      name="status" id="cars"
      onChange={handleChange}
    >
      <option value="0">CANCELED</option>
      <option value="1">ACCEPTED</option>
      <option value="2">PROCESSING</option>
      <option value="3">SENT</option>
      <option value="4">DELIVERED</option>
    </select>
  )
}